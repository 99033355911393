<template>
  <div>
    <Modal
      :value="value"
      title="集散点详情"
      @on-visible-change="modalChange"
      @input="(val) => $emit('input', val)"
      fullscreen
    >
      <Form :label-width="100" style="display: flex">
        <div class="form-title" style="width: 200px">
          <h4>集散点信息</h4>

          <FormItem>
            <img v-if="data.logo!=''" :src="imgSrc" alt="" width="100" height="100" />
            <img v-else src="/imgs/empty/empty.png" alt="" width="100" height="100" />

          </FormItem>

          <div style="width: 100%; text-align: center">公司logo</div>
        </div>
        <Row style="margin-top: 20px">
          <Col span="8">
            <FormItem label="集散点：">
              <Input
                v-model="data.serviceName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="业务范围：">
              <Input
                v-model="data.businessScope"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8" style="padding-left:58px">
            <FormItem label="联系人：">
              <Input v-model="data.name" readonly style="width: 260px"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="联系电话：">
              <Input v-model="data.tel" readonly style="width: 260px"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="营业期限：">
              <Input
                v-model="data.businessTerm"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8" style="padding-left:58px">
            <FormItem label="省市区编码：">
              <Input
                v-model="data.regionCodeName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="企业类型：">
              <Input
                v-model="data.enterpriseTypeName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="所属行业：">
              <Input
                v-model="data.industry"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>

          <Col span="8">
            <FormItem label="统一社会信用代码：" :label-width="160">
              <Input
                v-model="data.creditCode"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="7">
            <FormItem label="联系地址：">
              <Input
                type="textarea"
                :row="5"
                v-model="data.serviceAddress"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>

      <Form style="display: flex;margin:40px 0">
        
        <Row>
          <Col span="3">
          <div class="form-title" style="width: 200px">
          <h4>设备信息</h4>

          <FormItem>
            <img v-if="deviceImg!=''" :src="deviceImg" alt="" width="100" height="100" />
            <img v-else src="/imgs/empty/empty.png" alt="" width="100" height="100">
          </FormItem>

          <div style="width: 100%; padding-left: 20px">设备图片</div>
        </div></Col>
          <Col span="21" >
            <Table :columns="columns1" :data="deviceInfoData" :width="1200"></Table>
          </Col>
        </Row>
      </Form>

      <Form style="display: flex;margin-top:40px">
        
        <Row>
          <Col span="3">
          <div class="form-title" style="width: 200px">
          <h4>设施信息</h4>

          <FormItem>
            <img v-if="facilityImg!=''" :src="facilityImg" alt="" width="100" height="100" />
            <img v-else src="/imgs/empty/empty.png" alt="" width="100" height="100" />
          </FormItem>

          <div style="width: 100%; padding-left: 20px">设施图片</div>
        </div></Col>
          <Col span="21" >
            <Table :columns="columns2" :data="facilityInfoData" :width="1200"></Table>
          </Col>
        </Row>
      </Form>
       
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => this.$emit('input', false)">确定</Button>
      </p>

    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      imgSrc: "",
      deviceImg:'',
      facilityImg:'',
      servicePath:'',
      facilityImgData:'',
      deviceImgData:'',
      facilityInfoData: [],
      deviceInfoData: [],
      columns1: [
        {
          title: "设备名称",
          minWidth: 100,
          key: "name",
        },
        {
          title: "设备图片",
          width: 100,
          align: "center",
          render: (h, { row }) => {
              if(row.img !=''){
                return (
                <img
                  src={this.getImgUrl(this.servicePath, row.img)}
                  style="width: 35px;height: 35px"
                />
              );
              }else{
                return(
                  <span>--</span>
                );
              }
          },
        },
        {
          title: "设备类型",
          minWidth: 100,
          key: "categoryName",
        },
        {
          title: "数量",
          width: 100,
          render: (h, { row }) => {
            return <span>{row.num + "/" + row.unit}</span>;
          },
        },
        {
          title: "使用状态",
          width: 100,
          render: (h, { row }) => {
            return (
              <span>
                {row.status == 0 ? "闲置" : row.status == 1 ? "工作" : "废弃"}
              </span>
            );
          },
        },
        {
          title: "第一次投入使用时间",
          width: 180,
          key: "firstUseTime",
        },
        {
          title: "处理能力",
          minWidth: 100,
          key: "deal",
        },
        {
          title: "规格",
          minWidth: 100,
          key: "spec",
        },
      ],
      columns2: [
          {
            title: "设施名称",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "规模(长度，容量)",
            minWidth: 100,
            render: (h, { row }) => {
              let size = JSON.parse(row.size);
              return (
                <span>
                  {size.type == "1"
                    ? size.value.long+'m'
                    : `${size.value.long}×${size.value.width}×${size.value.high}m³`}
                </span>
              );
            },
          },
          {
            title: "设施图片",
            width: 135,
            render: (h, { row }) => {
              if (row.img) {
                return (
                  <div style="display:flex">
                    {row.img.split(",").map((item) => {
                      return (
                        <img
                          src={this.getImgUrl(this.servicePath, item)}
                          style="width: 35px;height: 35px;margin-right:10px"
                        />
                      );
                    })}
                  </div>
                );
              } else {
                return <span>--</span>;
              }
            },
          },
          {
            title: "图纸",
            width: 135,
            render: (h, { row }) => {
              if (row.drawings) {
                return (
                  <div style="display:flex">
                    {row.drawings.split(",").map((item) => {
                      return (
                        <img
                          src={this.getImgUrl(this.servicePath, item)}
                          style="width: 35px;height: 35px;cursor:pointer;margin-right:10px"
                          onClick={() =>
                            this.lookPicture(this.servicePath + item)
                          }
                        />
                      );
                    })}
                  </div>
                );
              } else {
                return <span>--</span>;
              }
            },
          },
          {
            title: "设施状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <span>
                  {row.status == 0 ? "闲置" : row.status == 1 ? "工作" : "废弃"}
                </span>
              );
            },
          },
        ],
      
    };
  },
  methods: {
    modalChange(visible) {
      if(visible){
      this.data = this.nodalPointDetailInfo;
      this.facilityInfoData = this.facilityData;
      this.deviceInfoData = this.deviceData;
      this.servicePath=this.imgServicePath
      
 
      if(this.deviceInfoData.length==1){
          this.deviceInfoData.forEach((item)=>{
        this.deviceImgData=item.img 
      })
       this.deviceImg = this.getImgUrl(this.servicePath, this.deviceImgData)

      }else{
        this.deviceImg = ''
      }
     
      if(this.facilityInfoData.length==1){
          this.facilityInfoData.forEach((item)=>{
        this.facilityImgData=item.img 
      })
      this.facilityImg = this.getImgUrl(this.servicePath, this.facilityImgData)
      }else{
        this.facilityImg= ''
      }

      }
      this.imgSrc = this.getImgUrl(this.servicePath, this.data.logo);
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    nodalPointDetailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    facilityData: {
      type: Array,
      default: () => {
        return {};
      },
    },
    deviceData: {
      type: Array,
      default: () => {
        return {};
      },
    },
    imgServicePath:{
      type:String,
      default:()=>{
        return {};
      }
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ div {
  .ivu-form-item {
    .ivu-form-item-content {
      margin-left: 0 !important;
    }
  }
  .form-title {
    padding: 10px 20px;
  }
  h4 {
    margin-bottom: 10px;
  }
}
</style>